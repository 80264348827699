import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfdrawer';
const owner = 'esign';

export const esignDrawers = {
	sendForSignatureDrawer: `${prefix}:${namespace}:${owner}:send-for-signature` as const,
	createFromTemplateDrawer:
		`${prefix}:${namespace}:${owner}:create-from-template` as const,
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomOverlayOptionsMap {
		[esignDrawers.sendForSignatureDrawer]: any;
	}
}
