import React, { lazy, Suspense } from 'react';
import { LoadingPrimary } from '@sharefiledev/flow-web';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { ShareFileUserContextProvider } from '../Contexts/ShareFileUserContext';
import { ReactQueryClientProvider } from '../data/ReactQueryClientProvider';

const LazySignatureRequestTAPDrawer = lazy(() => import('./SignatureRequestTAPDrawer'));
export const AsyncSignatureRequestTAPDrawer: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<ShareFileUserContextProvider {...props.piletApi.sf.piletUserContext.get()}>
				<ReactQueryClientProvider>
					<LazySignatureRequestTAPDrawer {...props} />
				</ReactQueryClientProvider>
			</ShareFileUserContextProvider>
		</Suspense>
	);
};

const LazySendForSignatureDrawer = lazy(() => import('./SendForSignatureDrawer'));
export const AsyncSendForSignatureDrawer: React.FC<{
	piletApi: PiletApi;
	isCreateFromTemplate?: boolean;
}> = props => {
	return (
		<ShareFileUserContextProvider {...props.piletApi.sf.piletUserContext.get()}>
			<LazySendForSignatureDrawer {...props} />
		</ShareFileUserContextProvider>
	);
};
